<template>
  <EditarBase
    nomeSingular="Inquilino"
    nomePlural="Inquilinos"
    @recurso="recurso = $event"
    @submitSuccess="emitSubmitSuccess"
    @submitError="emitSubmitError"
    @closeModalEdit="closeModalEdit"
  >
    <v-col cols="12">
      <v-row>
        <v-col cols="10">
          <v-span class="opacity-5 text-dark">
            {{ $t("Tipo_pessoa") }}
          </v-span>
          <CamposTipoNregistro
            :nRegistro="recurso.nRegistro"
            :tipoCliente="recurso.tipoCliente"
            @nRegistro="recurso.nRegistro = $event"
            @tipo="recurso.tipoCliente = $event"
            :disabled="true"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></CamposTipoNregistro>
        </v-col>
        <v-col cols="12" md="2">
          <v-span class="opacity-5 text-dark">
            {{ $t("Status") }}
          </v-span>
          <v-select
            v-model="recurso.statusComum"
            :items="statusComumItems"
            item-text="text"
            item-value="value"
            label="Status comum"
            single-line
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("Fantasia") }}
          </v-span>
          <v-text-field
            v-model="recurso.fantasia"
            :label="$t('Fantasia')"
            name="fantasia"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          >
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-span class="opacity-5 text-dark">
            {{ $t("RazaoSocial") }}
          </v-span>
          <v-text-field
            v-model="recurso.razaoSocial"
            :label="$t('RazaoSocial')"
            name="razaoSocial"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          />
        </v-col>

        <v-col md="9" cols="9" sm="9">
          <v-span class="opacity-5 text-dark">
            {{ $t("Tipo_contratacao") }}
          </v-span>
          <v-select
            v-model="recurso.tipoContratacao"
            :items="itensTipoContratacao"
            :label="$t('Tipo_contratacao')"
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
          ></v-select>
        </v-col>
        <v-col class="d-flex" cols="3" md="3" sm="3">
          <ModalImagem
            v-if="recurso.id"
            :modalImagem="modalImagem"
            @modalImagem="modalImagem = $event"
            :id="recurso.id"
          >
            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click="modalImagem = true"
            >
              <v-icon left dark> mdi-plus-circle </v-icon>
              {{ $t("Enviar_imagem") }}
            </v-btn>
          </ModalImagem>
        </v-col>

        <v-col cols="12">
          <CriaSuperSet
            :podeEditarRecursoFinal="false"
            @recursos="recurso.modulosContratacao = $event"
            :usaRecursoBase="false"
            :payload="recurso.modulosContratacao"
            tituloFinais="Modulos_contratacao"
            subtituloFinais="Clique_para_adicionar"
            :headersFinais="[
              {
                text: this.$t('Tipo'),
                value: 'tipo',
                sortable: 'true',
              },

              { text: this.$t('Acoes'), value: 'actions', sortable: 'false' },
            ]"
            textoBotaoCadastro="Criar_item"
          >
            <template v-slot:default="slotProps">
              <!-- Form cria recurso final -->
              <v-col cols="12" md="12">
                <v-select
                  :items="modulosContratacaoInquilino"
                  :label="$t('Tipo')"
                  v-model="slotProps.recursoFinalAtual.tipo"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                ></v-select>
              </v-col>
            </template>
          </CriaSuperSet>
        </v-col>

        <v-col cols="12" v-if="recurso.endereco">
          <v-row>
            <v-col cols="12" md="3">
              <v-span class="opacity-5 text-dark">
                {{ $t("CEP") }}
              </v-span>
              <BuscaCep
                @cidades="cidades = $event"
                @bairro="recurso.endereco.bairro = $event"
                @cep="recurso.endereco.cep = $event"
                @cidade="recurso.endereco.cidade = $event"
                @logradouro="recurso.endereco.logradouro = $event"
                :cepProp="recurso.endereco.cep || ''"
              ></BuscaCep>
            </v-col>
            <v-col cols="12" md="4">
              <v-span class="opacity-5 text-dark">
                {{ $t("Cidade") }}
              </v-span>
              <v-autocomplete
                :label="$t('Cidade')"
                outlined
                v-model="recurso.endereco.cidade"
                :items="cidades"
                return-object
                item-text="nome"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              >
              </v-autocomplete>
            </v-col>

            <!-- <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('Descricao')"
                  class="purple-input"
                  name="empresa_descricao"
                />
              </v-col> -->

            <v-col cols="12" md="5">
              <v-span class="opacity-5 text-dark">
                {{ $t("Bairro") }}
              </v-span>
              <v-text-field
                v-model="recurso.endereco.bairro"
                :label="$t('Bairro')"
                name="bairro"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>

            <v-col cols="12" md="10">
              <v-span class="opacity-5 text-dark">
                {{ $t("Logradouro") }}
              </v-span>
              <v-text-field
                v-model="recurso.endereco.logradouro"
                :label="$t('Logradouro')"
                name="logradouro"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-span class="opacity-5 text-dark">
                {{ $t("Numero") }}
              </v-span>
              <v-text-field
                v-model="recurso.endereco.numero"
                :label="$t('Numero')"
                name="empresa_numero"
                type="number"
                @blur="buscaGeoCode"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-span class="opacity-5 text-dark">
                {{ $t("Complemento") }}
              </v-span>
              <v-text-field
                v-model="recurso.endereco.complemento"
                :label="$t('Complemento')"
                name="empresa_complemento"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-span class="opacity-5 text-dark">
                {{ $t("Latitude") }}
              </v-span>
              <v-text-field
                v-model="recurso.endereco.latitude"
                :label="$t('Latitude')"
                name="latitude"
                type="number"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-span class="opacity-5 text-dark">
                {{ $t("Longitude") }}
              </v-span>
              <v-text-field
                v-model="recurso.endereco.longitude"
                :label="$t('Longitude')"
                name="longitude"
                type="number"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-span class="opacity-5 text-dark">
                {{ $t("nIe") }}
              </v-span>
              <v-text-field
                v-model="recurso.inscricaoEstadual"
                :label="$t('nIe')"
                name="nIe"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-span class="opacity-5 text-dark">
                {{ $t("nIm") }}
              </v-span>
              <v-text-field
                v-model="recurso.inscricaoMunicipal"
                :label="$t('nIm')"
                name="nIm"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>

            <v-col cols="12" md="2">
              <v-span class="opacity-5 text-dark">
                {{ $t("OrigemEstoque") }}
              </v-span>
              <v-text-field
                v-model="recurso.origemEstoque"
                :label="$t('OrigemEstoque')"
                name="origemEstoque"
                type="number"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-span class="opacity-5 text-dark">
                {{ $t("matrizFinanceira") }}
              </v-span>
              <v-text-field
                v-model="recurso.matrizFinanceira"
                :label="$t('matrizFinanceira')"
                name="matrizFinanceira"
                type="number"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-span class="opacity-5 text-dark">
                {{ $t("matrizOperacional") }}
              </v-span>
              <v-text-field
                v-model="recurso.matrizOperacional"
                :label="$t('matrizOperacional')"
                name="matrizOperacional"
                type="number"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-span class="opacity-5 text-dark">
                {{ $t("matrizOperacionalPonto") }}
              </v-span>
              <v-text-field
                v-model="recurso.matrizOperacionalPonto"
                :label="$t('matrizOperacionalPonto')"
                name="matrizOperacionalPonto"
                type="number"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-span class="opacity-5 text-dark">
                {{ $t("Site") }}
              </v-span>
              <v-text-field
                v-model="recurso.site"
                :label="$t('Site')"
                name="site"
                class="input-style font-size-input text-light-input placeholder-light input-icon"
                dense
                flat
                filled
                solo
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </EditarBase>
</template>

<script>
import BuscaCep from "@/components/base/proprios/BuscaCep";
import InquilinoDataService from "@/services/software/InquilinoDataService";
const DataService = InquilinoDataService;

import EditarBase from "@/components/base/Crud/EditarBase";
import CamposTipoNregistro from "@/components/base/proprios/CamposTipoNregistro";
import CriaSuperSet from "@/components/base/proprios/CriaSuperset";
import ModalImagem from "@/components/base/proprios/ModalImagem";
import listas from "@/mixins/listas";
import ComumDataService from "@/services/ComumDataService";
export default {
  name: "CadastroInquilino",
  components: {
    CamposTipoNregistro,
    EditarBase,
    ModalImagem,
    BuscaCep,
    CriaSuperSet,
  },
  props: {
    recurso: Object,
  },
  mixins: [listas],
  data() {
    return {
      cidades: [],
      submited: false,
      DataService: DataService,
      modalImagem: false,

      recurso: {
        fantasia: "",
        nRegistro: "",
        razaoSocial: "",
        tipoCliente: "",
        tipoContratacao: "",
        modulosContratacao: [],
        recurso: {
          endereco: {
            cep: "",
            cidade: {},
            bairro: "",
            logradouro: "",
          },
          fantasia: "",
          razaoSocial: "",
          tipoCliente: "",
          tipoContratacao: "",
          nregistro: "",
          imagem: "string",
          imagem64: "string",
          gestorCep: "",
          gestorCidade: {},
          gestorBairro: "",
          gestorLogradouro: "",
        },
      },

      errors: [],

      itensTipo: [
        { text: this.$t("Pessoa_fisica"), value: "PESSOAFISICA" },
        { text: this.$t("Pessoa_juridica"), value: "PESSOAJURIDICA" },
      ],
      itensTipoContratacao: [
        { text: this.$t("RRP"), value: "RRP" },
        { text: this.$t("GESTAOEFETIVO"), value: "GESTAOEFETIVO" },
        //{ text: this.$t("RRPPLUS"), value: "RRPPLUS" },
        // { text: this.$t("GESTAOEFETIVOPLUS"), value: "GESTAOEFETIVOPLUS" },
      ],

      statusComumItems: [
        { text: "Ativo", value: "ATIVO" },
        { text: "Inativo", value: "INATIVO" },
      ],
    };
  },
  methods: {
    emitSubmitSuccess() {
      this.$emit("submitSuccess");
    },
    emitSubmitError() {
      this.$emit("submitError");
    },
    closeModalEdit() {
      this.$emit("closeModalEdit");
    },
    buscaGeoCode() {
      if (this.recurso.endereco.numero.length >= 1) {
        ComumDataService.buscaGeoCode(
          this.enderecoParaString(this.recurso.endereco)
        ).then((response) => {
          if (response.data.results[0]) {
            this.recurso.endereco.latitude =
              response.data.results[0].geometry.location.lat;
            this.recurso.endereco.longitude =
              response.data.results[0].geometry.location.lng;
          }
        });
      }
    },
    enderecoParaString(endereco) {
      var string = "";

      if (endereco.logradouro) string = endereco.logradouro;

      if (endereco.numero) string = string + ", " + endereco.numero;

      if (endereco.bairro) string = string + ", " + endereco.bairro;

      return string;
    },
  },
};
</script>

<style scoped></style>
